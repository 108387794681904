<template>
  <div class="doc">
    <Page>
      <!-- <template v-slot:breadcrumbs> breadcrumbs </template> -->
      <div class="doc-header">
        <h1 class="doc-title">Инструкция по удалению аккаунта</h1>
      </div>
      <div class="doc-content doc-content-outer">
        <div class="doc-content-inner wh100">
          <object
            data="/static/index/files/account-deletion-manual.pdf#navpanes=0&pagemode=none&view=FitH"
            title="Инструкция по удалению аккаунта"
            type="application/pdf"
            class="wh100"
          >
            Ваш браузер не поддерживает просмотр PDF-файла
            <div class="doc__files">
              <a
                :href="require('@/assets/files/account-deletion-manual.pdf')"
                target="_blank"
                class="file-link"
              >
                Скачать инструкцию
              </a>
            </div>
          </object>
        </div>
      </div>
    </Page>
  </div>
</template>
  
<script>
import Page from "@/components/base/Page.vue";

export default {
  name: "Delete",
  components: { Page },
  mounted() {
    window.location.href = require('@/assets/files/account-deletion-manual.pdf');
  },
};
</script>

<style scoped>
  .doc-content-outer {
    position: relative;
    margin: 2rem 0px;
    padding-bottom: 90%;
  }

  .doc-content-inner {
    position: absolute;
  }

  .wh100 {
    height: 100%;
    width: 100%;
  }
</style>